<template>
	<div>
		<div class="flex items-center mb-5 mt-5">
			<div class="w-9/12 items-center">
				<a class="underline text-blue-500" href="#tableIP">Посмотреть статистику по IP-адресам за указанный в фильтре период</a>
			</div>
			<div class="flex justify-end w-4/12 items-center">
				<Button
					v-if="userIsInRoles(['Support', 'MZ_Employee'])"
					text="Удалить анкеты"
					icon="trash_for_btn"
					class="mr-2"
					@click.native="selectedAnkets.length === 0 || loadToDeleteAnkets ? '' : deleteItems()"
					:disabled="selectedAnkets.length === 0 || loadToDeleteAnkets"
					:load="loadToDeleteAnkets"
				/>
				<Button text="Экспорт в Excel" icon="exel" @click.native="downloadExcell(params)" :disabled="loadToExcell" :load="loadToExcell" />
			</div>
		</div>
		<div class="flex flex-col relative">
			<div class="t-overflow rounded-t-lg overflow-x-auto h-auto bg-white filter shadow-default" :class="{ 'custom-w-table-144': !menu, 'custom-w-table-360': menu }">
				<table class="w-full" :style="list.length > 0 ? 'min-height: 300px' : ''">
					<thead>
						<tr>
							<th
								class="px-6 py-3 border-b border-r border-gray-200 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider"
								v-for="(item, ind) in fields"
								:class="{ 'w-9/12': ind === 0 }"
								:key="'J' + ind"
							>
								<span class="relative">
									<span class="border-b border-gray-200 cursor-pointer" :class="{ 'text-blue-500 underline': filterBy === ind }" @click="switchFilterVisibility(ind)">
										{{ item.Name }}
									</span>

									<!-- Фильтр и сортировка -->
									<FilterComponent
										:position="ind === 0 ? 'left' : 'right'"
										:filterOpen="filterOpen"
										v-on:change="switchFilterVisibility(null)"
										:id-filter="ind"
										:param="params"
										@query="getData"
										:fields="fields"
										:defaultSortField="'RegionName'"
										:item="item"
									/>
								</span>
							</th>
						</tr>
					</thead>
					<tbody class="bg-white" v-for="(item, index) in list" :key="'G' + index">
						<tr :class="{ 'bg-gray-30': item.openMO }">
							<td class="relative underline text-sm whitespace-no-wrap border-b border-r border-gray-200 leading-5 text-gray-500">
								<span class="cursor-pointer px-6 py-4" @click="openMOfunk(item)">
									{{ item.regionName }}
								</span>
								<div v-if="item.openLoad" class="absolute w-full h-full preloader cursor-wait">
									<loadingComponent class="absolute top-1/4 left-1/2" />
								</div>
							</td>
							<td class="px-6 py-4 text-sm font-bold whitespace-no-wrap border-b border-r border-gray-200 leading-5 text-gray-500">
								{{ item.count }}
							</td>
						</tr>
						<tr v-if="item.openMO" :class="{ 'bg-gray-20': item.openMO }">
							<td colspan="2">
								<table class="w-full">
									<thead>
										<tr>
											<th class="px-6 py-3 border-b border-gray-200 text-left text-xs leading-4 font-semibold text-gray-500 uppercase tracking-wider">Наименование МО</th>
											<th class="px-6 py-3 border-b border-gray-200 text-left text-xs leading-4 font-semibold text-gray-500 uppercase tracking-wider">
												Общее количество заполненных интерактивных анкет у МО
											</th>
											<th class="px-6 py-3 border-b border-gray-200 text-left text-xs leading-4 font-semibold text-gray-500 uppercase tracking-wider">
												Количество заполненных интерактивных анкет у МО за последний квартал
											</th>
											<th class="px-6 py-3 border-b border-gray-200 text-left text-xs leading-4 font-semibold text-gray-500 uppercase tracking-wider">
												Количество заполненных интерактивных анкет у МО за последний месяц
											</th>
											<th class="px-6 py-3 border-b border-gray-200 text-left text-xs leading-4 font-semibold text-gray-500 uppercase tracking-wider">
												Количество заполненных интерактивных анкет у МО за последние 7 дней
											</th>
											<th class="px-6 py-3 border-b border-gray-200 text-left text-xs leading-4 font-semibold text-gray-500 uppercase tracking-wider">
												Количество заполненных интерактивных анкет у МО за последние 24 часа
											</th>
											<th class="px-6 py-3 border-b border-gray-200 text-left text-xs leading-4 font-semibold text-gray-500 uppercase tracking-wider">
												Количество подозрительных анкет
											</th>
										</tr>
									</thead>
									<tbody>
										<tr v-for="(it, ind1) in item.moList" :key="'U' + ind1">
											<td class="px-6 py-4 text-sm font-normal whitespace-no-wrap border-b border-gray-200 leading-5 text-gray-500">
												{{ it.moName }}
											</td>
											<td class="px-6 py-4 text-sm font-normal whitespace-no-wrap border-b border-gray-200 leading-5 text-gray-500">
												<div class="flex items-center">
													<span class="w-2/12">{{ it.count }}</span>
													<button
														@click="
															yearSelected.length > 1 || !it.count
																? ''
																: openDiagram(
																		yearSelected[0].year < 2022 || monthSelected.length > 0 || quarterSelected.length > 0 ? false : true,
																		false,
																		false,
																		false,
																		false,
																		it.moId,
																		'За последний год',
																		'Квартал',
																		it.moName,
																		!filterDate.from && !filterDate.to
																  )
														"
														:class="{
															'bg-blue-500 hover:bg-blue-400 cursor-pointer': !filterDate.from && !filterDate.to && yearSelected.length === 1 && it.count,
															'bg-gray-300 cursor-not-allowed': filterDate.from || filterDate.to || yearSelected.length > 1 || !it.count
														}"
														class="btn_default rounded-md ml-4"
													>
														<diagram />
													</button>
												</div>
											</td>
											<td class="px-6 py-4 text-sm font-normal whitespace-no-wrap border-b border-gray-200 leading-5 text-gray-500">
												<div class="flex items-center">
													<span class="w-2/12">{{ it.curentQuarter }}</span>
													<button
														@click="
															yearSelected.length > 1 || !it.curentQuarter
																? ''
																: openDiagram(false, true, false, false, false, it.moId, 'За последний квартал', 'Месяц', it.moName, !filterDate.from && !filterDate.to)
														"
														:class="{
															'bg-blue-500 hover:bg-blue-400 cursor-pointer': !filterDate.from && !filterDate.to && yearSelected.length === 1 && it.curentQuarter,
															'bg-gray-300 cursor-not-allowed': filterDate.from || filterDate.to || yearSelected.length > 1 || !it.curentQuarter
														}"
														class="btn_default rounded-md ml-4"
													>
														<diagram />
													</button>
												</div>
											</td>
											<td class="px-6 py-4 text-sm font-normal whitespace-no-wrap border-b border-gray-200 leading-5 text-gray-500">
												<div class="flex items-center">
													<span class="w-2/12">{{ it.curentMonth }}</span>
													<button
														@click="
															yearSelected.length > 1 || !it.curentMonth
																? ''
																: openDiagram(false, false, true, false, false, it.moId, 'За последний месяц', 'День', it.moName, !filterDate.from && !filterDate.to)
														"
														:class="{
															'bg-blue-500 hover:bg-blue-400 cursor-pointer': !filterDate.from && !filterDate.to && yearSelected.length === 1 && it.curentMonth,
															'bg-gray-300 cursor-not-allowed': filterDate.from || filterDate.to || yearSelected.length > 1 || !it.curentMonth
														}"
														class="btn_default rounded-md ml-4"
													>
														<diagram />
													</button>
												</div>
											</td>
											<td class="px-6 py-4 text-sm font-normal whitespace-no-wrap border-b border-gray-200 leading-5 text-gray-500">
												<div class="flex items-center">
													<span class="w-2/12">{{ it.lastSevenDays }}</span>
													<button
														@click="
															yearSelected.length > 1 || !it.lastSevenDays
																? ''
																: openDiagram(false, false, false, true, false, it.moId, 'За последние 7 дней', 'День', it.moName, !filterDate.from && !filterDate.to)
														"
														:class="{
															'bg-blue-500 hover:bg-blue-400 cursor-pointer': !filterDate.from && !filterDate.to && yearSelected.length === 1 && it.lastSevenDays,
															'bg-gray-300 cursor-not-allowed': filterDate.from || filterDate.to || yearSelected.length > 1 || !it.lastSevenDays
														}"
														class="btn_default rounded-md ml-4"
													>
														<diagram />
													</button>
												</div>
											</td>
											<td class="px-6 py-4 text-sm font-normal whitespace-no-wrap border-b border-gray-200 leading-5 text-gray-500">
												<div class="flex items-center">
													<span class="w-2/12">{{ it.last24hours }}</span>
													<button
														@click="
															yearSelected.length > 1 || !it.last24hours
																? ''
																: openDiagram(false, false, false, false, true, it.moId, 'За последние 24 часа', 'Часы', it.moName, !filterDate.from && !filterDate.to)
														"
														:class="{
															'bg-blue-500 hover:bg-blue-400 cursor-pointer': !filterDate.from && !filterDate.to && yearSelected.length === 1 && it.last24hours,
															'bg-gray-300 cursor-not-allowed': filterDate.from || filterDate.to || yearSelected.length > 1 || !it.last24hours
														}"
														class="btn_default rounded-md ml-4"
													>
														<diagram />
													</button>
												</div>
											</td>
											<td class="px-6 py-4 text-sm font-normal whitespace-no-wrap border-b border-gray-200 leading-5 text-gray-500">
												<div class="flex items-center">
													<span class="w-1/12">{{ it.countStrange }}</span>
													<button
														@click="info(it, it.countStrange, it.moName)"
														:class="{ 'bg-blue-500 hover:bg-blue-400 cursor-pointer': it.countStrange > 0, 'bg-gray-300 cursor-not-allowed': it.countStrange < 1 }"
														class="btn_default rounded-xl ml-4"
													>
														<question />
													</button>
													<input
														v-if="userIsInRoles(['Support', 'MZ_Employee'])"
														@change="addSelectedForAnkets(it)"
														:disabled="openCheckboxForDateFromTo || it.countStrange < 1"
														v-model="it.selected"
														class="ml-4 checkbox-w-h-20"
														:class="{ 'cursor-pointer': !openCheckboxForDateFromTo || it.countStrange > 0 }"
														type="checkbox"
													/>
												</div>
											</td>
										</tr>
									</tbody>
								</table>
							</td>
						</tr>
					</tbody>
				</table>
				<center v-if="!loadingTable && list.length === 0" class="w-full py-28 whitespace-no-wrap text-xl leading-5 text-gray-500 text-center">Данных нет...</center>
			</div>
			<!-- Навигация -->
			<div class="h-auto rounded-b-lg bg-white filter shadow-default">
				<div class="hidden sm:flex flex-row pt-6 px-20 pb-12 text-gray-400 text-sm">
					<div class="flex flex-row ml-auto">
						<p>Строк на странице:</p>
						<ul class="flex flex-row">
							<li @click="updateRow(5)" class="flex ml-4 cursor-pointer" :class="{ 'text-blue-500': rowsVisible == 5 }">5</li>
							<li @click="updateRow(10)" class="flex ml-4 cursor-pointer" :class="{ 'text-blue-500': rowsVisible == 10 }">10</li>
							<li @click="updateRow(15)" class="flex ml-4 cursor-pointer" :class="{ 'text-blue-500': rowsVisible == 15 }">15</li>
						</ul>
					</div>
					<div class="flex flex-row ml-24">
						<ul class="flex flex-row">
							<li class="flex ml-4 cursor-pointer arrow" @click="page !== 0 ? navigation('params', page - 1) : ''" />
							<li class="flex">
								<span>Стр</span>
								<b class="ml-2">{{ page + 1 }}</b>
								<span class="ml-2">из</span>
								<b class="ml-2">{{ totalCount === 0 ? 1 : totalCount }}</b>
							</li>
							<li class="flex ml-2 cursor-pointer arrow" @click="page + 1 < totalCount ? navigation('params', page + 1) : ''" />
						</ul>
					</div>
				</div>
			</div>

			<div v-if="loadingTable" class="absolute w-full h-full preloader rounded-lg">
				<loadingComponent class="absolute top-1/2 left-1/2" />
			</div>

			<transition name="fade">
				<div v-if="isModalVisible" class="overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none justify-center items-center flex">
					<div :class="{ 'w-8/12': isModalObj.diagram, 'w-7/12': isModalObj.input, 'w-2/12': isModalObj.delete }" class="relative my-6 mx-auto max-w-6xl">
						<!--content-->
						<div class="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
							<!--header-->
							<div class="flex items-start justify-between p-5 border-b border-solid border-blueGray-200 rounded-t-lg bg-blue-50">
								<h3 class="text-xl font-semibold">{{ isModalObj.title }}</h3>
								<span class="cursor-pointer text-blue-500" @click="isModalVisible = false">
									<close />
								</span>
							</div>
							<!--body-->
							<div class="relative p-6 flex-auto" :class="{ 'pt-0': isModalObj.diagram }">
								<p v-if="isModalObj.text.length > 0">{{ isModalObj.text }}</p>
								<Diagram
									v-if="isModalObj.diagram"
									:year="yearSelected"
									:medicalId="medicalId"
									:title="title"
									:series="dataDiagram"
									:xaxisCategories="xaxisCategories"
									:period="period"
									:xaxisTitle="xaxisTitle"
									:typeDiagramm="typeDiagramm"
									:quarterSelected="quarterSelected"
									:monthSelected="monthSelected"
									:currentObjForDiagramm="currentObjForDiagramm"
								/>

								<table-for-strange-ankets v-if="isModalObj.table" :nameMo="nameMo" :dataStrangeAnkets="dataStrangeAnkets" />
							</div>
							<!--footer-->
							<div v-if="!isModalObj.diagram && !isModalObj.table" class="flex items-center justify-center p-6 border-t border-solid border-blueGray-200 rounded-b">
								<button
									v-if="isModalObj.viewBtnCancel"
									@click="isModalVisible = false"
									class="text-blue-500 border border-solid border-blue-500 active:bg-red-600 font-bold text-sm px-6 py-3 rounded outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
									type="button"
									:disabled="disabledBtnSave"
								>
									Отмена
								</button>
								<button
									v-if="isModalObj.viewBtnSave"
									class="text-white bg-blue-500 font-bold px-6 py-3 text-sm outline-none rounded focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
									type="button"
									:disabled="disabledBtnSave"
								>
									Сохранить
								</button>
								<button
									v-if="isModalObj.viewBtnNo"
									@click="isModalVisible = false"
									class="text-blue-500 border border-solid border-blue-500 active:bg-red-600 font-bold text-sm px-6 py-3 rounded outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
									type="button"
									:disabled="disabledBtnSave"
								>
									Нет
								</button>
								<button
									v-if="isModalObj.viewBtnYes"
									class="text-white bg-blue-500 font-bold px-6 py-3 text-sm outline-none rounded focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
									type="button"
									:disabled="disabledBtnSave"
									@click="deleteApi(selectedAnkets)"
								>
									Да
								</button>
							</div>
						</div>
					</div>
				</div>
			</transition>

			<div v-if="isModalVisible" class="opacity-25 fixed inset-0 z-40 bg-black"></div>
		</div>
	</div>
</template>

<script>
// Глобальные данные стора
import { getters, mutations, methods } from '@/store/store';
// Локальные данные стора
import actions from '../store/actions';
import FilterComponent from '../../../../components/Filter.vue';
import loadingComponent from '../../../../components/Loading.vue';
import Button from '@/components/Button.vue';
import diagram from '../../../../assets/img/diagram2.svg';
import question from '../../../../assets/img/question.svg';
import close from '../../../../assets/img/close.svg';
import Diagram from './Diagram.vue';
import TableForStrangeAnkets from './tableForStrangeAnkets.vue';

export default {
	name: 'TableRegion',
	props: {
		region: {
			type: Array,
			default: () => []
		},
		years: {
			type: Array,
			default: () => []
		},
		quarter: {
			type: Array,
			default: () => []
		},
		month: {
			type: Array,
			default: () => []
		},
		dateFrom: {
			type: String,
			default: () => ''
		},
		dateTo: {
			type: String,
			default: () => ''
		},
		resetYearQuarterMonth: {
			type: Boolean,
			default: () => false
		}
	},
	components: {
		loadingComponent,
		FilterComponent,
		Button,
		Diagram,
		diagram,
		question,
		close,
		TableForStrangeAnkets
	},
	computed: {
		...getters
	},
	watch: {
		region(val) {
			if (val.length === 1 && val[0].Id === 0) {
				let index = this.params.filters.findIndex((f) => f.fieldName === 'RegionId');
				if (index > -1) this.params.filters.splice(index, 1);

				this.getData(this.params);

				return;
			}

			this.addParams(
				'RegionId',
				val.map((m) => m.Id),
				8,
				2
			);
		},
		years(val) {
			if (val.length > 0) {
				this.addParams(
					'Years',
					val.map((m) => m.year),
					7,
					2
				);
			} else {
				let index = this.params.filters.findIndex((f) => f.fieldName === 'Years');
				if (index > -1) this.params.filters.splice(index, 1);

				if (!this.resetYearQuarterMonth) this.getData(this.params);
			}
			this.yearSelected = val;

			console.log('this.yearSelected', this.yearSelected);
		},
		quarter(val) {
			if (val.length > 0) {
				this.params.columns = val.map((m) => m.quarterEng);
				this.getData(this.params);
			} else {
				let pc = this.params.columns;

				for (let i = 0; i < pc.length; i += 1) {
					if (pc[i] === 'FirstQuarter' || pc[i] === 'SecondQuarter' || pc[i] === 'ThirdQuarter' || pc[i] === 'FourthQuarter') {
						this.params.columns.splice(i, 1);
					}
				}

				if (!this.resetYearQuarterMonth) this.getData(this.params);
			}
			this.quarterSelected = val;

			console.log('this.quarterSelected', this.quarterSelected);
		},
		month(val) {
			if (val.length > 0) {
				this.params.columns = val.map((m) => m.monthEng);
				this.getData(this.params);
			} else {
				let pc = this.params.columns;

				for (let i = 0; i < pc.length; i += 1) {
					if (
						pc[i] === 'January' ||
						pc[i] === 'February' ||
						pc[i] === 'March' ||
						pc[i] === 'April' ||
						pc[i] === 'May' ||
						pc[i] === 'June' ||
						pc[i] === 'July' ||
						pc[i] === 'August' ||
						pc[i] === 'September' ||
						pc[i] === 'October' ||
						pc[i] === 'November' ||
						pc[i] === 'December'
					) {
						this.params.columns.splice(i, 1);
					}
				}

				if (!this.resetYearQuarterMonth) this.getData(this.params);
			}
			this.monthSelected = val;

			console.log('this.monthSelected', this.monthSelected);
		},
		dateFrom(val) {
			if (val) {
				this.filterDate.from = val;
				this.params.columns = ['DateFilter'];

				if (val && this.filterDate.to && val < this.filterDate.to) {
					this.getData(this.params);
				}
			}
			if (!val) {
				let index = this.params.columns.findIndex((f) => f === 'DateFilter');
				if (index > -1) this.params.columns.splice(index, 1);

				let index2 = this.params.filters.findIndex((f) => f.fieldName === 'Date');
				if (index2 > -1) this.params.filters.splice(index2, 1);

				this.openCheckboxForDateFromTo = true;
				this.filterDate.from = null;
				this.filterDate.to = null;

				this.getData(this.params);
			}
		},
		dateTo(val) {
			if (this.filterDate.from && val) {
				this.addParams('Date', this.filterDate.from, 8, 2, val);
				this.filterDate.to = val;
				this.openCheckboxForDateFromTo = false;
			}

			if (!val) {
				this.openCheckboxForDateFromTo = true;
				this.filterDate.to = null;
			}
		}
	},
	data() {
		return {
			currentObjForDiagramm: {},
			quarterSelected: [],
			monthSelected: [],
			typeDiagramm: '',
			yearSelected: [],
			dataStrangeAnkets: [],
			nameMo: '',
			objDiagramm: {},
			medicalId: '',
			title: '',
			openCheckboxForDateFromTo: true,
			selectedAnkets: [],
			filterDate: {
				from: null,
				to: null
			},
			loadToDeleteAnkets: false,
			regionFlag: false,
			xaxisTitle: '',
			period: '',
			dataDiagram: [],
			xaxisCategories: [],
			obj: {},
			disabledBtnSave: false,
			isModalObj: {
				viewBtnYes: false,
				viewBtnNo: false,
				viewBtnCancel: false,
				viewBtnSave: false,
				title: '',
				input: false,
				delete: false,
				text: '',
				diagram: false,
				table: false
			},
			isModalVisible: false,
			loadingTable: false,
			loadToExcell: false,
			rowsVisible: 5,
			totalCount: 0,
			page: 0,
			filterOpen: null,
			filterBy: null,
			fields: [
				{
					Name: 'Регион',
					fieldName: 'RegionName',
					filterType: 2,
					filterValueType: 1,
					value1: '',
					value2: null,
					sortBy: 2,
					paramsFiter: '',
					label1: 'По возрастанию (А-Я)',
					label2: 'По убыванию (Я-А)'
				},
				{
					Name: 'Общее количество заполненных интерактивных анкет у региона.',
					fieldName: 'Count',
					filterType: 1,
					filterValueType: 2,
					value1: '',
					value2: null,
					sortBy: null,
					paramsFiter: '[0-9.]',
					label1: 'По возрастанию',
					label2: 'По убыванию'
				}
			],
			list: [],
			params: {
				filters: [],
				sortOption: {
					fieldName: 'RegionName',
					sortType: 2
				},
				pageSize: 5,
				currentPage: 0,
				columns: []
			}
		};
	},
	methods: {
		...mutations,
		...actions,
		...methods,
		switchFilterVisibility(key) {
			this.filterOpen = this.filterOpen === key ? null : key;
			this.filterBy = key;
		},
		updateRow(rowsVisible) {
			this.rowsVisible = rowsVisible;
			this.params.pageSize = rowsVisible;
			this.getData(this.params);
		},
		getData(params) {
			this.loadingTable = true;

			this.get({
				filters: params.filters,
				sortOption: {
					fieldName: params.sortOption.fieldName,
					sortType: params.sortOption.sortType
				},
				pageSize: params.pageSize,
				currentPage: params.currentPage,
				columns: [...params.columns, 'RegionCount']
			}).then((res) => {
				for (let i = 0; i < res.data.data.length; i += 1) {
					res.data.data[i].openMO = false;
					res.data.data[i].openLoad = false;
					res.data.data[i].moList = [];
				}

				this.loadingTable = false;
				this.list = res.data.data;
				this.totalCount = Math.ceil(res.data.totalRecords / this.rowsVisible);
			});
		},
		navigation(name, page) {
			this.page = page;
			this[name].currentPage = page;

			this.getData(this[name]);
		},
		downloadExcell(params) {
			this.loadToExcell = true;

			this.$http
				.post('Anketa/GetDynamicAnketingResultsExcel', params, {
					responseType: 'blob',
					headers: {
						'Access-Control-Expose-Headers': 'Content-Disposition'
					}
				})
				.then((res) => {
					// let fileNameAndType = decodeURI(res.headers['content-disposition'].split('filename*=')[1].split("''")[1]);

					const url = window.URL.createObjectURL(new Blob([res.data]));
					const link = document.createElement('a');
					link.href = url;
					link.setAttribute('download', 'Динамика заполнения.xlsx');
					document.body.appendChild(link);
					link.click();
					document.body.removeChild(link);

					this.loadToExcell = false;
				})
				.catch((error) => {
					if (error) {
						this.loadToExcell = false;
						this.$toast.error('Ошибка выгрузки, обратитесь к Администратору!', {
							position: 'top-right',
							timeout: 3000,
							closeOnClick: true,
							pauseOnFocusLoss: true,
							pauseOnHover: true,
							draggable: true,
							draggablePercent: 0.6,
							showCloseButtonOnHover: false,
							hideProgressBar: true,
							closeButton: 'button',
							icon: true,
							rtl: false
						});
					}
				});
		},
		openMOfunk(row) {
			if (row.openMO) {
				row.openMO = false;
				row.moList = [];
				row.openLoad = false;

				return;
			}

			row.openLoad = true;
			this.getMO({
				filters: [
					{
						fieldName: 'RegionId',
						filterType: 8,
						filterValueType: 2,
						value1: row.regionId
					},
					...this.params.filters
				],
				sortOption: {
					fieldName: '',
					sortType: null
				},
				pageSize: 999,
				currentPage: 0,
				columns: this.params.columns
			}).then((res) => {
				for (let i = 0; i < res.data.data.length; i += 1) {
					res.data.data[i].selected = false;
				}

				row.moList = res.data.data;
				row.openMO = !row.openMO;
				row.openLoad = false;
			});
		},
		deleteItems() {
			this.isModalObj.viewBtnCancel = false;
			this.isModalObj.viewBtnSave = false;
			this.isModalObj.viewBtnYes = true;
			this.isModalObj.viewBtnNo = true;
			this.isModalObj.input = false;
			this.isModalObj.delete = true;
			this.isModalObj.diagram = false;
			this.isModalObj.table = false;
			this.isModalObj.text = 'Вы уверены ?';
			this.isModalObj.title = 'Удалить анкеты';

			this.isModalVisible = true;
		},
		deleteApi(ankets) {
			let listAnketsId = ankets.map((m) => ({ id: m.moId })),
				currentRegion = ankets[0].regionId;
			this.disabledBtnSave = true;

			this.deleteStrangeAnkets(listAnketsId).then((res) => {
				if (res.status === 200) {
					for (let i = 0; i < this.list.length; i += 1) {
						if (this.list[i].regionId === currentRegion) {
							for (let j = 0; j < this.list[i].moList.length; j += 1) {
								for (let k = 0; k < listAnketsId.length; k += 1) {
									if (listAnketsId[k].id === this.list[i].moList[j].moId) {
										this.list[i].moList[j].countStrange = 0;
										this.list[i].moList[j].selected = false;
									}
								}
							}
						}
					}
					this.showNotification('Анкеты удалены успешно!', 'success');
					this.disabledBtnSave = false;
					this.isModalVisible = false;
				}
			});
		},
		info(row, count, moName) {
			if (count < 1) return;

			this.getDataTable(row.moId).then((res) => {
				this.isModalObj.viewBtnCancel = false;
				this.isModalObj.viewBtnSave = false;
				this.isModalObj.viewBtnYes = false;
				this.isModalObj.viewBtnNo = false;
				this.isModalObj.input = true;
				this.isModalObj.delete = false;
				this.isModalObj.table = true;
				this.isModalObj.diagram = false;
				this.isModalObj.text = '';
				this.isModalObj.title = 'Подозрительные данные';

				this.dataStrangeAnkets = res.data;
				this.nameMo = moName;

				this.isModalVisible = true;
			});
		},
		checkName(array, name, nameEng) {
			return array.map((m) => m[nameEng]).indexOf(name) !== -1;
		},
		openDiagram(lastYear, lastQuarter, lastMonth, lastSevenDays, last24Hours, moId, period, xaxisTitle, moName, flagClick) {
			if (!flagClick) return;

			this.medicalId = moId;
			if (lastYear) this.typeDiagramm = 'lastYear';
			if (lastQuarter) this.typeDiagramm = 'lastQuarter';
			if (lastMonth) this.typeDiagramm = 'lastMonth';
			if (lastSevenDays) this.typeDiagramm = 'lastSevenDays';
			if (last24Hours) this.typeDiagramm = 'last24Hours';
			else this.typeDiagramm = 'other';

			this.currentObjForDiagramm = {
				lastYear: lastYear,
				lastQuarter: lastQuarter,
				lastMonth: lastMonth,
				lastSevenDays: lastSevenDays,
				last24Hours: last24Hours,
				medicalId: moId,
				year: this.yearSelected[0].year,
				firstQuarter: this.checkName(this.quarterSelected, 'FirstQuarter', 'quarterEng'),
				secondQuarter: this.checkName(this.quarterSelected, 'SecondQuarter', 'quarterEng'),
				thirdQuarter: this.checkName(this.quarterSelected, 'ThirdQuarter', 'quarterEng'),
				forthQuarter: this.checkName(this.quarterSelected, 'FourthQuarter', 'quarterEng'),
				january: this.checkName(this.monthSelected, 'January', 'monthEng'),
				february: this.checkName(this.monthSelected, 'February', 'monthEng'),
				march: this.checkName(this.monthSelected, 'March', 'monthEng'),
				april: this.checkName(this.monthSelected, 'April', 'monthEng'),
				may: this.checkName(this.monthSelected, 'May', 'monthEng'),
				june: this.checkName(this.monthSelected, 'June', 'monthEng'),
				july: this.checkName(this.monthSelected, 'July', 'monthEng'),
				august: this.checkName(this.monthSelected, 'August', 'monthEng'),
				september: this.checkName(this.monthSelected, 'September', 'monthEng'),
				october: this.checkName(this.monthSelected, 'October', 'monthEng'),
				november: this.checkName(this.monthSelected, 'November', 'monthEng'),
				december: this.checkName(this.monthSelected, 'December', 'monthEng'),
				month: this.monthSelected.length > 1
			};

			this.getDataDiagrams(this.currentObjForDiagramm).then((res) => {
				this.isModalObj.viewBtnCancel = false;
				this.isModalObj.viewBtnSave = false;
				this.isModalObj.viewBtnYes = false;
				this.isModalObj.viewBtnNo = false;
				this.isModalObj.input = false;
				this.isModalObj.delete = false;
				this.isModalObj.table = false;
				this.isModalObj.diagram = true;
				this.isModalObj.text = '';
				this.isModalObj.title = 'Количество заполненных интерактивных анкет';

				this.dataDiagram = [
					{
						name: 'Анкет',
						data: res.data.yAxis
					},
					{
						name: 'Подозрительных анкет',
						data: res.data.yAxisStrangeCount
					}
				];
				this.xaxisCategories = res.data.xAxis;

				if (this.quarterSelected.length > 0) {
					this.period = `${this.yearSelected[0].year} - ${this.quarterSelected.map((m) => m.quarter).join(', ')}`;
					this.xaxisTitle = '';
				} else if (this.monthSelected.length > 0) {
					this.period = `${this.yearSelected[0].year} - ${this.monthSelected.map((m) => m.month).join(', ')}`;
					this.xaxisTitle = '';
				} else {
					this.period = period;
					this.xaxisTitle = xaxisTitle;
				}
				this.title = moName;

				this.isModalVisible = true;
			});
		},
		addParams(fieldName, value, filterType, filterValueType, value2) {
			let flag = value2 ?? false;
			if (this.params.filters.length > 0) {
				for (let i = 0; i < this.params.filters.length; i += 1) {
					if (this.params.filters[i].fieldName === fieldName) {
						if ((value.length === 1 && value[0] === 0) || value.length === 0) {
							this.params.filters.splice(i, 1);

							this.getData(this.params);
							return;
						}
						this.params.filters[i].value1 = flag ? value : value.toString();
						if (flag) this.params.filters[i].value2 = value2;
						this.getData(this.params);

						return;
					}
				}
				this.params.filters.push({
					fieldName: fieldName,
					filterType: filterType,
					filterValueType: filterValueType,
					value1: flag ? value : value.toString(),
					value2: flag ? value2 : undefined
				});

				this.getData(this.params);
				return;
			}

			if (value.length > 0 || value) {
				this.params.filters.push({
					fieldName: fieldName,
					filterType: filterType,
					filterValueType: filterValueType,
					value1: flag ? value : value.toString(),
					value2: flag ? value2 : undefined
				});
			}
			this.getData(this.params);
		},
		addSelectedForAnkets(row) {
			if (row.selected) {
				this.selectedAnkets.push(row);

				return;
			}

			let index = this.selectedAnkets.findIndex((f) => f.moId === row.moId);
			if (index > -1) this.selectedAnkets.splice(index, 1);
		},
		showNotification(text, type) {
			this.$toast[type](text, {
				position: 'top-right',
				timeout: 3000,
				closeOnClick: true,
				pauseOnFocusLoss: true,
				pauseOnHover: true,
				draggable: true,
				draggablePercent: 0.6,
				showCloseButtonOnHover: false,
				hideProgressBar: true,
				closeButton: 'button',
				icon: true,
				rtl: false
			});
		}
	}
};
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style>
.checkbox-w-h-20 {
	width: 20px;
	height: 20px;
}

.btn_default {
	width: 24px;
	height: 24px;
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	padding: 0px;
	gap: 10px;
}

input[type='date'] {
	background: initial;
	box-sizing: content-box;
	display: block;
	position: relative;
	min-height: 50px;
	text-align: left;
	color: #35495e;
	border: 1px solid gray;
	border-radius: 10px;
	padding-left: 12px;
	padding-right: 4px;
}
.multiselect__tags {
	font-weight: 400;
	font-size: 14px;
	line-height: 50px;
	min-height: 50px;
	width: 100%;
	border-radius: 10px;
	border: 1px solid #a8b1ce;
	padding: 0 20px;
	color: #6a6e83;
	background: initial;
}
.multiselect__tags-wrap {
	display: flex;
	align-items: center;
	height: 50px;
}
.multiselect__option--highlight:after {
	display: none;
}
.multiselect__select {
	height: 50px;
}
.label {
	align-items: center;
	cursor: pointer;
}
.label:hover .label-text {
	text-decoration: none;
}
.label-text {
	text-align: center;
	font-weight: 700;
	font-size: 14px;
	line-height: 18px;
	text-decoration-line: underline;
	color: #3377ff;
}

.input-box {
	position: relative;
	margin-bottom: 20px;
}

.input-box label {
	font-weight: 700;
	font-size: 14px;
	display: block;
	line-height: 18px;
	margin-bottom: 12px;
	color: #a8b1ce;
}

.spinner {
	animation: rotator 1.4s linear infinite;
	position: relative;
	top: -1px;
}

@keyframes rotator {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(270deg);
	}
}

.path {
	stroke-dasharray: 187;
	stroke-dashoffset: 0;
	transform-origin: center;
	animation: dash 1.4s ease-in-out infinite;
}

@keyframes dash {
	0% {
		stroke-dashoffset: 187;
	}
	50% {
		stroke-dashoffset: 46.75;
		transform: rotate(135deg);
	}
	100% {
		stroke-dashoffset: 187;
		transform: rotate(450deg);
	}
}

.preloader {
	z-index: 10;
	background: rgba(0, 0, 0, 0.09);
}

.sticky-table__header1 {
	left: 0;
	z-index: 9;
	position: sticky;
	text-align: center;
	background-color: rgba(249, 250, 251);
}
.sticky-table__header2 {
	left: 9.9rem;
	z-index: 8;
	position: sticky;
	text-align: center;
	background-color: rgba(249, 250, 251);
}
.sticky-table__row1 {
	background-color: #fff;
	left: 0;
	position: sticky;
}
.sticky-table__row2 {
	background-color: #fff;
	left: 9.9rem;
	position: sticky;
}

li.arrow::before {
	@apply block w-2 h-2 mt-1.5 mr-2 border-b-2 border-l-2 border-gray-400;
	content: '';
	transform: rotate(45deg);
}
.arrow:last-child::before {
	transform: rotate(-135deg);
}

.t-overflow::-webkit-scrollbar {
	height: 11px; /* высота полосы прокрутки */
}

.t-overflow::-webkit-scrollbar-track {
	background: rgba(168, 177, 206, 0.3); /* цвет зоны отслеживания */
}

.t-overflow::-webkit-scrollbar-thumb {
	background: #3377ff;
	border-radius: 10px;
}

.custom-w-table-144 {
	max-width: calc(100vw - 144px - 3rem);
}

.custom-w-table-360 {
	max-width: calc(100vw - 360px - 3rem);
}

.custom-input:not(:checked) {
	display: none;
}

.custom-input:checked::after {
	content: '';
	position: absolute;
	display: block;
	width: 17px;
	height: 17px;
	top: -2px;
	left: -2px;
	background: url('~@/assets/img/filter-tick.svg') no-repeat;
	z-index: 40;
}
.activeF {
	background: #3377ff;
}
</style>
